import React, { useState } from "react"
import { Link } from 'gatsby'
import MenuIcon from '../styles/icons/menu'
import ContactIcon from '../styles/icons/menu-contact'
import FeaturesIcon from '../styles/icons/menu-features'
import PricingIcon from '../styles/icons/menu-pricing'
import Logo from '../images/logo.svg'

const Header = () => {

  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <header className={`fixed w-full z-30 top-0 bg-white text-heading-dark px-4 shadow-2xl ${menuOpen ? 'lg:h-auto md:h-auto h-full' : null}`}>
      <div className="w-full container mx-auto flex flex-wrap items-center justify-between py-5">
        <div className="items-center space-x-10 flex">
          <Link to="/">
            <img src={Logo} alt="Handdy Logo" />
          </Link>
          <nav className="hidden space-x-5 font-bold text-sm  lg:flex md:flex tracking-tight">
            <Link activeClassName="text-primary" to="/features">FEATURES</Link>
            <Link activeClassName="text-primary" to="/pricing">PRICING</Link>
            <Link activeClassName="text-primary" to="/contact">CONTACT</Link>
          </nav>
        </div>
        <div className="space-x-4 items-center font-bold text-sm hidden lg:flex md:flex">
          <a href="#" className="text-sm">SIGN IN</a>
          <button className="font-medium focus:outline-none text-white bg-primary text-xs leading-3 py-3 px-5 rounded-md bg-blue-500 hover:bg-blue-600 hover:shadow-lg">Free 30-day trial</button>
        </div>
        <div className="flex lg:hidden md:hidden">
          <span onClick={() => setMenuOpen(!menuOpen)} role="button">
            <MenuIcon />
          </span>
        </div>
      </div>
      {menuOpen && <nav className="flex lg:hidden md:hidden flex-col">
        <ol className="grid gap-7 w-full">
          <li className="w-full">
            <Link to="/features" className="flex items-center space-x-4 text-dark-soft font-medium">
              <FeaturesIcon />
              <span>Features</span>
            </Link>
          </li>
          <li className="w-full">
            <Link to="/pricing" className="flex items-center space-x-4 text-dark-soft font-medium">
              <PricingIcon />
              <span>Pricing</span>
            </Link>
          </li>
          <li className="w-full">
            <Link to="/contact" className="flex items-center space-x-4 text-dark-soft font-medium">
              <ContactIcon />
              <span>Contact Us</span>
            </Link>
          </li>
        </ol>
        <a className="text-primary my-6" href="#">Book your free demo →</a>
        <div className="grid grid-cols-2 gap-4">
          <Link to="/contact" className="text-dark-soft font-medium">Contact Us</Link>
          <a className="text-dark-soft font-medium">Contact sales</a>
          <a className="text-dark-soft font-medium">Blog</a>
          <a className="text-dark-soft font-medium">Careers</a>
          <a className="text-dark-soft font-medium">Terms & Privacy</a>
          <a target="_blank" href="https://www.globalintegra.com/" className="text-dark-soft font-medium">Integra ↗️</a>
        </div>
        <button className="font-medium focus:outline-none text-white bg-primary text-xs leading-3 py-3 px-5 rounded-md bg-blue-500 hover:bg-blue-600 hover:shadow-lg lg:my-8 my-4">Start Free Trial</button>
        <a className="text-body-muted text-center" href="#">Existing customer? <span className="text-primary">Sign in</span></a>
      </nav>
      }
    </header>
  )
}


export default Header
