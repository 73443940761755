import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 32 32"
        >
            <circle
                cx="16"
                cy="16"
                r="15"
                stroke="#E8E7E8"
                strokeWidth="0.834"
                opacity="0.24"
            ></circle>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M14.212 21.637h2.049v-5.628h1.537l.304-1.759h-1.841v-1.274c0-.411.266-.842.645-.842h1.047v-1.759H16.67v.008c-2.006.073-2.419 1.235-2.454 2.456h-.004v1.411h-1.025v1.759h1.025v5.628z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default Icon;
