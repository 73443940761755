import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 32 32"
        >
            <circle
                cx="16"
                cy="16"
                r="15"
                stroke="#E8E7E8"
                strokeWidth="0.834"
                opacity="0.24"
            ></circle>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M20.992 11.51c-.317.203-1.046.498-1.406.498a2.255 2.255 0 00-3.827 2.06c-1.689-.043-3.536-.89-4.648-2.338-.684 1.183-.092 2.5.684 2.979-.266.02-.755-.03-.985-.255-.015.785.362 1.826 1.74 2.203-.266.143-.735.102-.94.072.072.663 1 1.53 2.016 1.53-.362.418-1.723 1.177-3.251.936a6.774 6.774 0 003.527.997c3.637 0 6.462-2.948 6.31-6.585v-.012-.029l-.001-.033a3.887 3.887 0 001.093-1.154c-.184.101-.737.305-1.25.355.33-.178.818-.761.939-1.225z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default Icon;
