import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <rect width="40" height="40" fill="#0BAD4B" rx="6"></rect>
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M25 28h5v-2a3 3 0 00-5.356-1.857M25 28H15m10 0v-2c0-.656-.126-1.283-.356-1.857M15 28h-5v-2a3 3 0 015.356-1.857M15 28v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M23 15a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zm-14 0a2 2 0 11-4 0 2 2 0 014 0z"
            ></path>
        </svg>
    );
}

export default Icon;
