import React from 'react'
import { Link } from 'gatsby'
import Logo from '../../images/logo.svg'
import TwitterIcon from '../../styles/icons/twitter'
import FacebookIcon from '../../styles/icons/facebook'
import LinkedinIcon from '../../styles/icons/linkedin'


function Footer() {
    return (
        <footer className="lg:pt-16 md:pt-8 sm:pt-8 pt-4 lg:pb-10 md:pb-6 sm:pb-4 pb-4 bg-heading-darker text-body-dark px-4">
            <div className="container mx-auto ">
                <div className="grid lg:grid-cols-2">
                    <div className="grid gap-5">
                        <img alt="Footer Logo" src={Logo}></img>
                        <div className="flex space-x-4 mb-6">
                            <TwitterIcon />
                            <FacebookIcon />
                            <LinkedinIcon />
                        </div>
                    </div>
                    <nav className="grid lg:grid-cols-3 text-sm gap-6">
                        <ul>
                            <li className="text-primary-light font-semibold mb-1">Product</li>
                            <li><Link to="/features">Features</Link></li>
                            <li><Link to="/pricing">Pricing</Link></li>
                            <li><a href="#">Learning Center</a></li>
                            <li><a href="#">Referral Program</a></li>
                        </ul>
                        <ul>
                            <li className="text-primary-light font-semibold mb-1">Company</li>
                            <li><Link to="/about">About</Link></li>
                            <li><a href="#">Terms</a></li>
                            <li><a href="#">Privacy Policy</a></li>
                            <li><a href="#">Careers</a></li>
                        </ul>
                        <ul>
                            <li className="text-primary-light font-semibold mb-1">More</li>
                            <li><Link to="/contact">Contact us</Link></li>
                            <li><a href="#">Book your free demo</a></li>
                        </ul>
                    </nav>
                </div>
                <p className="text-xxs lg:mt-16 md:mt-12 sm:mt-8 mt-4">2004-2021 © Integra Global Solutions Corp</p>
            </div >
        </footer >
    )
}

export default Footer
