import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 32 32"
        >
            <circle
                cx="16"
                cy="16"
                r="15"
                stroke="#E8E7E8"
                strokeWidth="0.834"
                opacity="0.24"
            ></circle>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M11.777 12.937c.775 0 1.403-.573 1.403-1.28 0-.708-.628-1.282-1.402-1.282-.776 0-1.404.574-1.404 1.281 0 .708.628 1.28 1.403 1.28zm-1.082 8.232h2.516v-7.257h-2.516v7.257zm7.385-5.683c-.941 0-1.335.814-1.335 1.647v3.943h-2.512V13.82h2.512v.977c.661-.853 1.235-1.206 2.277-1.206s2.736.494 2.736 3.36v4.126h-2.505v-3.963c0-.813-.231-1.627-1.173-1.627z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default Icon;
