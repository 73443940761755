import React from "react"
import Header from "./header"
import Footer from "./Footer"

const Layout = ({ children }) => {

  return (
    <>
      <Header />
      <main className="pt-17">
        {children}
      </main>
      <Footer />
    </>
  )
}

export default Layout
