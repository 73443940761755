import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <rect width="40" height="40" fill="#0BAD4B" rx="6"></rect>
            <path
                fill="#fff"
                d="M21.843 17.655a1 1 0 101.511-1.31l-1.51 1.31zm-3.686 4.69a1 1 0 00-1.51 1.31l1.51-1.31zM21 15a1 1 0 10-2 0h2zm-2 10a1 1 0 102 0h-2zm9-5a8 8 0 01-8 8v2c5.523 0 10-4.477 10-10h-2zm-8 8a8 8 0 01-8-8h-2c0 5.523 4.477 10 10 10v-2zm-8-8a8 8 0 018-8v-2c-5.523 0-10 4.477-10 10h2zm8-8a8 8 0 018 8h2c0-5.523-4.477-10-10-10v2zm0 7c-.659 0-1.21-.18-1.567-.418C18.07 18.34 18 18.114 18 18h-2c0 .99.602 1.765 1.324 2.246.729.486 1.678.754 2.676.754v-2zm-2-1c0-.114.07-.34.433-.582.358-.238.909-.418 1.567-.418v-2c-.998 0-1.947.268-2.676.754C16.602 16.234 16 17.009 16 18h2zm2-1c.904 0 1.563.332 1.843.655l1.511-1.31C22.597 15.472 21.317 15 20 15v2zm0 4c.659 0 1.21.18 1.567.418.364.243.433.468.433.582h2c0-.99-.602-1.765-1.324-2.246C21.947 19.268 20.998 19 20 19v2zm-1-6v1h2v-1h-2zm0 9v1h2v-1h-2zm1-1c-.904 0-1.563-.332-1.843-.655l-1.51 1.31C17.402 24.528 18.682 25 20 25v-2zm2-1c0 .114-.07.34-.433.582-.358.238-.908.418-1.567.418v2c.998 0 1.947-.268 2.676-.754C23.398 23.766 24 22.991 24 22h-2zm-3-6v8h2v-8h-2z"
            ></path>
        </svg>
    );
}

export default Icon;
