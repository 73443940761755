import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="41"
            fill="none"
            viewBox="0 0 40 41"
        >
            <path
                fill="#fff"
                d="M0 6.41a6 6 0 016-6h28a6 6 0 016 6v28a6 6 0 01-6 6H6a6 6 0 01-6-6v-28z"
            ></path>
            <path
                stroke="#9CA3AF"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 14.41h16m-16 6h16m-16 6h16"
            ></path>
        </svg>
    );
}

export default Icon;
